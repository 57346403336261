import instance, { setAuthHeader } from './axiosEnv';

const getUserInfo = (token) => {    
    const config = setAuthHeader(token);
    return instance.get('/auth/me', config);
}

const authAPI = {
    getUserInfo
}

export default authAPI;