import instance, { setAuthHeader } from './axiosEnv';

const getLocationThresholds = (locationId) => {
    return instance.get(`/thresholds/${locationId}/thresholds`)
}

const updateThreshold = (token, thresholdId, newThreshold) => {
    const config = setAuthHeader(token);
    return instance.put(`thresholds/${thresholdId}`, newThreshold, config)
}

const postThreshold = (token, newThreshold) => {
    const config = setAuthHeader(token);
    return instance.post('thresholds', newThreshold, config)
}

const thresholdsAPI = { 
    getLocationThresholds,
    updateThreshold,
    postThreshold 
}
export default thresholdsAPI;