import instance, { setAuthHeader } from './axiosEnv';

const getUserGroups = (token, groupname) => {
    const config = setAuthHeader(token);
    return instance.get('/keycloak/'+ groupname, config);
}

const keycloakAPI = {
    getUserGroups
}

export default keycloakAPI;